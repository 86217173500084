import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /* login(userName: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/login`, {
        userName,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  } */

  login(data) {
    return this.http
      .post<any>(`${environment.apiUrl}Token`, data)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  customerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Customers';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerCreditLimit(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerCreditLimit/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Customers';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  _customerList1(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Customers/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  einvoiceList(id, type): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'EInvoice/' + id + '/' + type;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  companyDetails(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CompanyDetails';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  companyList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Company';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  companyDetails1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CompanyDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  companySettings(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CompanySettings/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  companySettings1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CompanySettings/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updatePrefix(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'UpdatePrefix/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateInstanceId(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'UpdateInstanceId/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateClExpiry(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'UpdateClExpiry/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  excelImport(id, formData) {
    let URL = environment.apiUrl + 'OrderExcelUpload/' + id;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  excelImport2(id, newOrderId, companyId, formData) {
    let URL = environment.apiUrl + 'InvoiceExcelUpload/' + id + '/' + newOrderId + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  excelImport2F(id, newOrderId, companyId, formData) {
    let URL = environment.apiUrl + 'InvoiceExcelUploadFrame/' + id + '/' + newOrderId + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  excelImport2A(id, newOrderId, companyId, formData) {
    let URL = environment.apiUrl + 'InvoiceExcelUploadAccessory/' + id + '/' + newOrderId + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  excelImport2CL(id, newOrderId, companyId, formData) {
    let URL = environment.apiUrl + 'InvoiceExcelUploadCL/' + id + '/' + newOrderId + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  excelImportCheck(id, companyId, formData) {
    let URL = environment.apiUrl + 'CheckExcelBarcode/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  excelImportCheckCL(id, companyId, formData) {
    let URL = environment.apiUrl + 'CheckExcelBarcodeCL/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  excelImportCheckF(id, companyId, formData) {
    let URL = environment.apiUrl + 'CheckExcelBarcodeFrame/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  excelImportCheckA(id, companyId, formData) {
    let URL = environment.apiUrl + 'CheckExcelBarcodeAcessory/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  excelImportCheck1(formData) {
    let URL = environment.apiUrl + 'CheckBarcodeExist';
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  excelImport1(id, companyId, formData) {
    let URL = environment.apiUrl + 'GrnExcelUpload/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  uploadLogo(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'CompanyLogo/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
    );
  }
  uploadQrCode(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'QrCode/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }


  checkFrameBarcode(formData) {
    let URL = environment.apiUrl + 'FrameExcelBarcodeCheck';
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  checkAccessoryBarcode(formData) {
    let URL = environment.apiUrl + 'AccessoryExcelBarcodeCheck';
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  checkCLBarcode(formData) {
    let URL = environment.apiUrl + 'CLExcelBarcodeCheck';
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  checkOLBarcode(formData) {
    let URL = environment.apiUrl + 'OLExcelBarcodeCheck';
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  checkCLBarcode1(formData) {
    let URL = environment.apiUrl + 'CLExcelBarcodeCheck1';
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  clExcelBarcodeStockCheck(companyId, type, formData) {
    let URL = environment.apiUrl + 'CLExcelBarcodeStockCheck/' + companyId + '/' + type;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  clExcelBarcodeStockInCheck(mainOutId, type, formData) {
    let URL = environment.apiUrl + 'CLExcelBarcodeStockInCheck/' + mainOutId + '/' + type;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  uploadFrameBarcode(id, companyId, formData) {
    let URL = environment.apiUrl + 'FrameOrderExcelUpload/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  uploadAccessoryBarcode(id, companyId, formData) {
    let URL = environment.apiUrl + 'AccessoryOrderExcelUpload/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  uploadCLBarcode(id, formData) {
    let URL = environment.apiUrl + 'CLOrderExcelUpload/' + id;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  uploadOLBarcode(id, formData) {
    let URL = environment.apiUrl + 'OLOrderExcelUpload/' + id;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  uploadFrameBarcode1(id, companyId, formData) {
    let URL = environment.apiUrl + 'FrameGrnExcelUpload/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  uploadAccessoryBarcode1(id, companyId, formData) {
    let URL = environment.apiUrl + 'AccessoryGrnExcelUpload/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  uploadCLBarcode1(id, companyId, formData) {
    let URL = environment.apiUrl + 'CLGrnExcelUpload/' + id + '/' + companyId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  clStockExcelUpload(id, type, formData) {
    let URL = environment.apiUrl + 'CLStockExcelUpload/' + id + '/' + type;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  purchaseReturnExcelUpload(id, type, formData) {
    let URL = environment.apiUrl + 'PurchaseReturnExcelUpload/' + id + '/' + type;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  clStockInExcelUpload(inId, outId, type, formData) {
    let URL = environment.apiUrl + 'CLStockInExcelUpload/' + inId + '/' + outId + '/' + type;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  printInvoiceDetails(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'PrintInvoiceDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  OpticalLensOrderDataDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'OpticalLensOrderData/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  OpticalLensOrderDataUpdate(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'OpticalLensOrderData/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  _customerList2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerOrder/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  cusPagination(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerPoolIdWise/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  cusList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerPoolIdWiseOD/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerCompanyWise(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerCompanyWise/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerSearch(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerSearch';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerSearchOrder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerSearchOrder';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  draftOrder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'DraftOrder';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  draftGrn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'DraftGrn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  draftTransferOut(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'DraftTransferOut';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addTransferOutMain(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockTransferOutMain';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addTransferInMain(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockTransferInMain';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addTransferOutDetails(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockOutDetails';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addTransferInDetails(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockInDetails';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateStockOutQty(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'UpdateStockOutQty/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  transferOutDetails(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockTransferOutMain/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  transferInDetails(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockTransferInMain/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  transferOutDetailsData(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockOutDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  transferInDetailsData(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockInDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  stockTransferOutRequest(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockTransferOutRequest/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  draftDcGrn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'DraftDCGrn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addNewOrder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrder';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addNewOrderDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrderDetail';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewOrderDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrderDetail/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewOrderDetail1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrderDetail1/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewOrderDetail2(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrderDetail2/'+id+'/'+companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewOrderDetail3(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrderDetail3/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getGrnDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GrnDetail/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getGrnDetail2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GrnDetail1/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getGrnDetail3(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GrnDetail2/'+id+'/'+companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getGrnDetail4(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GrnDetail3/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  invoiceOrderList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'InvoiceOrderList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  invoiceOrderList1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'InoviceOrders';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addOnComboList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'AddonComboList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  fittingPrice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'FittingPrice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addNewInvoiceDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewInvoiceDetail';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerForDC(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerForDc';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  VendorForDC(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'VendorForDc';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewInvoiceDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewInvoiceDetail/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewInvoiceDetail1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewInvoiceCLDetail/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewInvoiceDetail2(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewInvoiceDetail2/' + id + '/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getNewInvoiceDetail3(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewInvoiceDetail3/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateNewInvoice(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewInvoice/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateNewSaleReturn(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewSalesReturn/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  eInvoice(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'EInvoice/' + id;
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  eInvoiceSubmit(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'EInvoice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateNewPurchaseReturn(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewPurchaseReturn/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getGrnDetail1(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GrnBarcodeSearch/'+id+'/'+companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addGrnDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GrnDetail';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateNewOrder(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrder/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateNewOrder1(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewOrderUpdate/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updatePurchase(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Purchases/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateGrn(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GRN/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Frames';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Accessories';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  bankAccountList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BankAccountList/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  referenceNoList(customerId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ReferenceNoList/' + customerId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  searchGiftCode(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'SearchGiftCode';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addCustomerReceipt(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerReceipt';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerReceiptList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomerReceipt/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerReceiptDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'PaymentDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Frames/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameBarcodeCheck(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'FrameBarcodeCheck';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameValidationCheck(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'FrameValidation';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameValidationCheck1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'FrameValidation1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  contactLensList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ContactLens';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  gstList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Gsts';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  contactLensList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ContactLens/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  userList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'MyUsers/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getUser1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'MyUserDetail/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateLocation(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Company/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  prefixVerification(companyId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PrefixVerification/' + companyId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Admin/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addUser(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Admin';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  brandList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Brands';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  clBrandList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ClBrands';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  olDiaList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'OlDiameters';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  brandTypeList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BrandType';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  modalityList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Modalities';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  vendorList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Vendors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  vendorPoolIdWise(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'VendorPoolIdWise/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  grnVendorList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GrnVendorList';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addNewPurchaseReturn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewPurchaseReturn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  vendorList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Vendors/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  barcodeSelection(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BarcodeSelection';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  barcodeSelection2(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BarcodeSelection2';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  barcodeSelection20(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BarcodeSelection20';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  barcodeSelection21(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BarcodeSelection21';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  barcodeSelection3(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BarcodeSelection3';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addContactLensStockDate(type, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ContactLensStock/' + type;
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  checkBatchCodeExist(contactLensDetailId, batchCode, batchBarCode): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ContactLensStock/' + contactLensDetailId + '/' + batchCode + '/' + batchBarCode;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  companyList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CompanyList/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  companyListing(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CompanyListing';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  saleReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'SaleReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  barcodeSelection1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BarcodeSelection1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  purchaseList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Purchases';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  grnList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GRN';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  grnList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GRN1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  stockTransferOutMainList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockTransferOutMain';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  DcGrnList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'DcGrn/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPurchase(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Purchases';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addGrn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GRN';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPurchase(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewPurchaseReturn/'+ id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getGrn(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GRN/'+ id + '/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  changeSupplier(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ChangeSupplier/'+ id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  reallocateList(id, grnQty): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Reallocate/'+ id + '/' + grnQty;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  reallocate(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Reallocate/'+ id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  purchaseList1(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewPurchaseReturnList/' + userId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPurchase1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'PurchaseReturns';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPurchase1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'PurchaseReturns/'+ id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  //====================================== SALE

  saleList(_companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewSalesReturn/' + _companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  saleList1(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewSalesReturn1/' + userId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addSale(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'SaleReturns';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSale(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'NewSalesReturn/'+ id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  previousInvoice(id, companyId, type): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'PreviousInvoice/'+ id + '/' + companyId + '/' + type;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  stateList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'States';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomReport1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customSaleReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomSaleReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customPurchaseReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomPurchaseReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customSaleReturnReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomSalesReturnReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customPurchaseReturnReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'CustomPurchaseReturnReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  tallyPurchaseReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TallyPurchaseReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  tallyPurchaseReturnReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TallyPurchaseReturnReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  tallySaleReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TallySaleReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  tallySalesReturnReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TallySalesReturnReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  summarizeOrderReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'SummarizeOrderReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  stockLensReport(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockLensInventoryReport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  countryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Countries';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  cityList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'States/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  tenderReport(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customer/tenderreport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  reviewReport(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/reviewreport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  installerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'installer/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  blogList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'blog/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  projectList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'project/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  testimonialList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'testimonial/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  installerList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'installer/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  tenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  openTenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/opentender';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  notOpenTenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/notopentender';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteInstaller(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Admin/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  awardTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/award/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  closeTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/close/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteInstaller1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  changepassword(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/password/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteBlog(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteProject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteTestimonial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Customers/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteFrame(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Frames/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteAccessory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Accessories/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteFrame1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Frames1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteVariation(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VAriationDelete/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteVariation1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VariationDelete1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteContactLens1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLens1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteContactLens(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLens/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteCustomer1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerGroups/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteVendor(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Vendors/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteVendor1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VendorContacts/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Customers1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  fittingCharges(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FittingCharges/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getState(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'States1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  checkVerify(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Verify/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  onVerify(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Verify';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getFrame(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Frames/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAccessory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Accessories/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateFrameVariation(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Frames1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateAccessoryVariation(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Accessories1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getContactLens(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLens1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getContactLens1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ImportContactLens/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCustomerGroup(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerGroups/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  fromToOrder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FromToOrder';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  fromToInvoice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FromToInvoice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getVendor(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Vendors1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getVendorFittingPurchase(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VendorFittingPurchase/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  fittingDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FittingDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateFittingDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FittingDetails';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  onTest(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = 'https://hibroker.in/api/verify-mobile-number';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  companyDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Company/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerSearchRetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerSearchRetail';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  prescriptionList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PrescriptionList/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPrescription(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PrescriptionList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerSearchRetail1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerSearchRetail1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  CustomerGroupInsert(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerGroupInsert';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  samePanNoCompanies(panno, id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'SamePanNoCompanies/' + panno + '/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  userCompanyWise(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'UserCompanyWise/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  brandGroups(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BrandGroup';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  allow(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AllowNegativeInventory/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAllow(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Allow';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  allow1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AllowNegativeInventory';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateBrand(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Brands/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getVendorContact(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VendorContacts/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTestimonial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTenderCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/customer/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getBlog(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getProject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getInstaller(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPerformance(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/performance/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getApplicationList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/application/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateCustomer(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Customers/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateVendor(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Vendors/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateFrame(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Frames/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameSearchData(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameSearchData';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateStockOutDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockOutDetails/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateStockInDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockInDetails/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessorySearchData(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessorySearchData';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameOrder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameOrder';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoriesOrder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoriesOrder';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameStock(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameOrder/' + id + '/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoriesStock(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoriesOrder/' + id + '/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  checkContactLensStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CheckContactLensStock';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  checkOpticalLensStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CheckOpticalLensStock';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameInvoiceSubmit(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameInvoiceDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoriesInvoiceSubmit(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoryInvoiceDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameSearchData1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameSearchData1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  stockQtyCheck(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockQtyCheck/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  checkStockAlsoPending(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CheckStockAlsoPending';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  checkStockPurchaseReturn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CheckStockPurchaseReturn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessorySearchData1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessorySearchData1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameOrderDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameOrderDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoryOrderDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoryOrderDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameGrnDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameGrnDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoryGrnDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoryGrnDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  frameSalesReturnDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameSalesReturnDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoriesSalesReturnDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoriesSalesReturnDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  framePurchaseReturnDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FramePurchaseReturnDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  accessoriesPurchaseReturnDetail(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoriesPurchaseReturnDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  cusPayList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CusPayList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  paidAmount(id, type): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PaidAmount/' + id + '/' + type;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateContactLens(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLens/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateApplication(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateTestimonial(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateTender(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  forgotPassword(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/forgotpassword';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateBlog(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateProject(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateInstaller(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateUser(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Admin/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateGroup(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Groups/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addGroup(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Groups';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getGroup(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Groups1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  groupList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Groups';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  shapeList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalLensShapes';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  materialList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameMaterial';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  materialList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Materials';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  seasonList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameSeason';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  groupList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Groups/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  indexList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'IndexData';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  stateList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'States2';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  salePersonList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'SalePersons';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteGroup(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Groups/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteBrand(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Brands/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getBrand(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Brands/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addCustomer(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Customers';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addFrame(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Frames';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  checkAccessory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessoryCheck';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addAccessory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Accessories';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateAccessory(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Accessories/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addContactLens(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLens';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getContactLensList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLens1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  stockStatus(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockStatus';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addOrder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Orders';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addInvoice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Invoices';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addCLinvoiceList(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CLInvoiceDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  orderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Orders';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  /* orderList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewOrder';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  } */

    orderListGrn(companyId): Observable<any>{
      let token = localStorage.getItem("MYTOKEN");
        let httpAuthOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json',
              'Authorization': 'bearer ' + token,
            }
          ), 
        };
      let URL = environment.apiUrl + 'NewOrderGrn/' + companyId;
      return this.http.get<any>(URL, httpAuthOptions).
      pipe(
        map(res =>{
          return res;
        })
      ) 
    }

    orderByIdentifier(identifier): Observable<any>{
      let token = localStorage.getItem("MYTOKEN");
        let httpAuthOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json',
              'Authorization': 'bearer ' + token,
            }
          ), 
        };
      let URL = environment.apiUrl + 'OrderByIdentifier/' + identifier;
      return this.http.get<any>(URL, httpAuthOptions).
      pipe(
        map(res =>{
          return res;
        })
      ) 
    }

    fittingChargeValidation(data): Observable<any>{
      let token = localStorage.getItem("MYTOKEN");
        let httpAuthOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json',
              'Authorization': 'bearer ' + token,
            }
          ), 
        };
      let URL = environment.apiUrl + 'FittingValidation';
      return this.http.post<any>(URL, data, httpAuthOptions).
      pipe(
        map(res =>{
          return res;
        })
      ) 
    }

  orderList2(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewOrderContact/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  grnFilter(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GrnFilter';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  _orderList1(_userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewOrder1/' + _userId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  cancelOrder(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewOrderDetail1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  cancelOrder1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewOrderDetail2/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  fittingCharge(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FittingCharge';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getOrder(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Orders/' +id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getOrder1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewOrder/' +id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  invoiceList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoice';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  
  invoiceList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoice1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  dcList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DcInvoice';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  dcList1(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DcInvoice/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getInvoice(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoice/' +id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  visualisation(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Visualisation/' +id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  visualisationOrder(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VisualisationOrder/' +id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  cancelInvoice(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoiceDetail1/' +id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  cancelGrn(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GrnDetail1/' +id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  importContactLens(id, userId, formData): Observable<any>{
    let URL = environment.apiUrl + 'ImportContactLens/' + id + '/' + userId;
    return this.http.post<any>(URL, formData).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addBrand(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Brands';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  colourList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ColourList/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  contactLensStock(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLensStocks/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addContactLensStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ContactLensStocks';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getBarcode(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ColourList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewOrderDetail/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteInvoiceDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoiceDetail/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteSaleReturnDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSaleReturnDetail/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deletePurchaseReturnDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewPurchaseReturnDetail/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteGrnDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GrnDetail/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteStockOutDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockOutDetails/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteStockInDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockInDetails/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  ledgerList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'LedgerMaster/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  ledgerList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'LedgerList/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  _ledgerList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'LedgerList1/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  _ledgerList2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'LedgerList2/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  checkLedgerName(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CheckLedgerName';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addLedger(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'LedgerMaster';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addOd(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OtherDoc';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addExpense(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Expenses';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  odList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OtherDoc/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  expenseList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Expenses/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  odDetails(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OtherDocDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  expenseDetails(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ExpenseDetails/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  bankList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankMaster';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  activeBankList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ActiveBankList';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  bankAcList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankAccount/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  bankDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankMaster/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addBankAc(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankAccount';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  editBankAc(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankAccount/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addBank(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankMaster';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  editBank(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankMaster/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteBank(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankMaster/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteBankAc(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BankAccount/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  paymentMachineList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PaymentMachine/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  paymentMachineList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PaymentMachineList/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  paymentMachineList2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PaymentMachineList1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addPaymentMachine(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PaymentMachine';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updatePaymentMachine(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PaymentMachine/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deletePaymentMachine(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PaymentMachine/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  ////GIFT VOUCHER

  giftVoucherList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GiftVoucherMaster/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addGiftVoucherMaster(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GiftVoucherMaster';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateGiftVoucherMaster(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GiftVoucherMaster/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteGiftVoucherMaster(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GiftVoucherMaster/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  ////////////////////////

  deleteODDetails(id, totalValue): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OtherDocDetails/' + id + '/' + totalValue; 
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteExpenseDetails(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ExpenseDetails/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteOD(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OtherDoc/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  deleteExpense(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Expenses/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  editOD(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OtherDoc/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  editExpense(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Expenses/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  editLedger(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'LedgerMaster/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  ledgerStatus(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'LedgerMaster/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }



  updateGrn1(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'UpdateGrn/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateStockTransferOut(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockTransferOutMain/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateStockTransferIn(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockTransferInMain/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateDCGrn(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DcGrn/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  barcodeSearch(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GrnBarcodeSearch';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  stockbarcodeSearchOL(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockBarcodeSearch';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateOrderDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OrderDetailUpdate';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  searchBatchBarcode(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'SearchBatchBarcode';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  searchBatchBarcode1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'SearchBatchBarcode1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  frameShapeMaster(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameShape';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  frameMaterialMaster(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameMaterial';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  newSearchBatchBarcode(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSearchBatchBarcode';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  existStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ExistStock';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  checkExist(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CheckExist';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  stockReport(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockReport/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  olSearchStock(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OlSearchStock/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  frameSearchStock(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameSearchStock/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  frameFilterList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'FrameFilterList';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  accessorySearchStock(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AccessorySearchStock/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  otherLocationStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StockReport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  
  particularOrderDetailCL(newInvoiceMainId, newOrderDetailId, contactLensDetailId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ParticularOrderDetailCL/' + newInvoiceMainId + '/' + newOrderDetailId + '/' + contactLensDetailId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  customerList2(companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerList/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  creditDayCheck(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CreditDayCheck/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  customerData(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerData/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  vendorData(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VendorData/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  focalityList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalFocality';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  opticalFamily(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalFamily';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  opticalDesign(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalDesign';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  categoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalCategory';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  checkStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CheckStock';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  subCategoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalSubCategory';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  treatmentList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalTreatment';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addOnCombo(companyId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalLensAddOn/' + companyId;
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  opticalLensStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalLensStock';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  
  opticalLensStock1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalLensStock1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  poolList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Pools';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  poolList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PoolList';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  poolList2(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PoolList1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  poolList3(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PoolList2';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addPool(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Pools';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updatePool(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Pools/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  indexList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalIndex';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  _indexList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalIndex';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  coatingList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalCoating';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  mirrorList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalMirror';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  tintList(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalTint/'+id +'/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  gstCheck(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GstCheck';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  gstCheck1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GstCheck1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  mobEmailCheck(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'MobEmail';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  customerOrders(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerOrders/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  mobEmailCheck1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'MobEmail1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  opticalList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalMasters';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  canclInvoice(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoice/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getOrderList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OrderList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  draftInvoice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DraftInvoice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  draftDCInvoice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DraftDCInvoice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  
  dcInvoiceList(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DCInvoiceList/' + id + "/" + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  dcGrnList(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DCGrnList/' + id + '/' + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateDCInvoice(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DCInvoiceDetail/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  draftSalesReturn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DraftSalesReturn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  draftPurchaseReturn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DraftPurchaseReturn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addNewInvoice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addDCInvoice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DcInvoice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addDCGrn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'DcGrn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addNewSalesReturn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSalesReturn';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addNewSalesReturnDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSaleReturnDetail';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addNewPurchaseReturnDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewPurchaseReturnDetail';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewSaleReturnDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSaleReturnDetail/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewSaleReturnDetail1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSaleReturnDetail1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewSaleReturnDetail2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSaleReturnDetail2/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewSaleReturnDetail3(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewSaleReturnDetail3/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewPurchaseReturnDetail2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewPurchaseReturnDetail2/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewPurchaseReturnDetail3(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewPurchaseReturnDetail3/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewPurchaseReturnDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewPurchaseReturnDetail/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getNewPurchaseReturnDetail1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewPurchaseReturnDetail1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  customerInvoiceList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CustomerInvoiceList/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  salesReturnInvoice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'SalesReturnInvoice';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getFittingCharges(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OpticalFittingCharges/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  vendorGrnList(id, companyId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'VendorGrnList/' + id + "/" + companyId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  newInvoiceDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'NewInvoiceDetail1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  allocateBarcode(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AllocateBarcode';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateAllocateBarcode(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'AllocateBarcode/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getBatch(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OrderList1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getBatch1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'OrderList2';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  changeStatus(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ChangeStatus/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  changeStatus1(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ChangeStatus1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getPincode(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Cities';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addModality(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Modalities';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addVendor(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Vendors';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addBlog(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addProject(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addTestimonial(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addInstaller(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addInstaller1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addTender(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addApplication(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getApplication(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/data';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        return this.apiResponse(event);
		break;
      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  blogUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'blog/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  profileUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'installer/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  profileUpload1(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'auth/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  projectUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'project/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  testimonialUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'testimonial/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
